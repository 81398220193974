'use client';

import type { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import ActionButton from '../../form/ActionButton/ActionButton';
import Badge from '../../ui/Badge/Badge';
import ToggleIconButton from '../../ui/ToggleIconButton/ToggleIconButton';
import styles from './CommonHeader.module.scss';

// biome-ignore lint/style/useNodejsImportProtocol: <explanation>
import type { UrlObject } from 'url';
import type { Route } from 'next';
import { useIsMenuOpenedAtom } from '../CommonMenu/CommonMenu';

function CommonHeader<T extends string>({
  initialSessionData,
  isOptionNotEntered = false,
  hasPendingEntry = false,
  hasAlertEcItem = false,
  siteName,
  logoHref = '/',
}: {
  initialSessionData?: ReturnType<typeof useSession>['data'];
  isOptionNotEntered?: boolean;
  hasPendingEntry?: boolean;
  hasAlertEcItem?: boolean;
  siteName: string;
  logoHref?: Route<T> | UrlObject;
}) {
  const { data: sessionData = initialSessionData } = useSession();
  const [isMenuOpenedValue, setIsMenuOpened] = useIsMenuOpenedAtom();

  const showBadge = (session: Session) => {
    return session.user.unreadMessageBoxCount > 0 || isOptionNotEntered || hasPendingEntry || hasAlertEcItem;
  };

  return (
    <header className={`${styles.componentStyle} ${isMenuOpenedValue ? styles.menuOpened : ''}`}>
      <div className={styles.container}>
        <Link href={logoHref} className={styles.logo}>
          <img src='/logo.png' alt={siteName} />
        </Link>
        {sessionData ? (
          <div className={styles.menuWrapper}>
            <ToggleIconButton isActive={isMenuOpenedValue} onClick={() => setIsMenuOpened(!isMenuOpenedValue)} />
            {showBadge(sessionData) && (
              <div className={styles.menuBadge}>
                <Badge color='primary' />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.auth}>
            <Link href='/auth/signin' className={styles.loginLink}>
              ログイン
            </Link>
            <div className={styles.signupButton}>
              <ActionButton
                color='primary'
                size='xSmall'
                onClick={() => {
                  location.href = `${process.env.NEXT_PUBLIC_MAIN_URL}/${process.env.NEXT_PUBLIC_SERVICE_ID}/auth/signup?callbackUrl=${location.href}`;
                }}
              >
                会員登録
              </ActionButton>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default CommonHeader;
