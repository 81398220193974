import sweetalert, { type SweetAlertOptions } from 'sweetalert2';

// ベースとなるスタイルを調整
const base = sweetalert.mixin({
  confirmButtonColor: 'var(--color-primary)',
  cancelButtonColor: '#999999',
  denyButtonColor: '#cc3333',
  confirmButtonText: 'OK',
  cancelButtonText: 'キャンセル',
});

const fire = (options: SweetAlertOptions) => {
  return sweetalert.fire(options);
};

/**
 * 確認
 * @returns
 */
const confirm = (options: {
  title?: string;
  text?: string;
  confirmButtonText?: string;
}) => {
  return base.fire({
    title: options?.title || '確認',
    html: options?.text || '操作を続行します。よろしいですか？',
    confirmButtonText: options?.confirmButtonText || 'OK',
    showCancelButton: true,
  });
};

/**
 * アラート
 * @returns
 */
const alert = (options: { text: string }) => {
  return base.fire({
    title: ' ',
    confirmButtonText: '閉じる',
    text: options.text,
  });
};

/**
 * 入力エラー
 * @returns
 */
const invalid = (text?: string) => {
  return base.fire({
    title: ' ',
    confirmButtonText: 'OK',
    text: text ? text : '入力内容を確認してください。',
  });
};

/**
 * エラーメッセージ
 * @returns
 */
const messages = (options: { messages: Array<string> }) => {
  return base.fire({
    title: ' ',
    confirmButtonText: 'OK',
    html: options.messages.join('<br>'),
  });
};

export const swal = {
  fire,
  confirm,
  alert,
  invalid,
  messages,
};
